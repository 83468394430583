<button class="btn btn-sm show-logs" (click)="open(content)">
  <fa-icon icon="list"></fa-icon>
  {{ 'admin.changes-logs-modal: Changes Log' | translate }}
</button>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'admin.changes-logs-modal: Changes Log' | translate }}
    </h4>
    <div>
      <fa-icon [classes]="['fa-fw']" icon='close' (click)="c()" class="clickable"></fa-icon>
    </div>
  </div>
  <div class="modal-body fit-content">
    <ng-container>
      <div class="card mt-3 p-4">
        <div class="row fw-bold border-bottom pb-2">
          <div class="col"><strong>{{ 'admin.changes-logs-modal: Change' | translate }}</strong></div>
          <div class="col"><strong>{{ 'admin.changes-logs-modal: Old value' | translate }}</strong></div>
          <div class="col"><strong>{{ 'admin.changes-logs-modal: New value' | translate }}</strong></div>
          <div class="col"><strong>{{ 'admin.changes-logs-modal: Changed on' | translate }}</strong></div>
          <div class="col"><strong>{{ 'admin.changes-logs-modal: Changed by' | translate }}</strong></div>
        </div>
        <ng-container *ngFor="let log  of answerLogs">
          <div>
            <hr>
            <div class="row pt-2">
              <div class="col">{{ propertyMapping[log.title] | translate }}</div>
              <div class="col">{{ log.oldValue }}</div>
              <div class="col">{{ log.newValue }}</div>
              <div class="col">{{ log.createdAt | date: 'MMM d, y, h:mm a' }}</div>
              <div *ngIf="log.user as user" class="col"><a (click)="c()" [routerLink]="'/admin/users/'+ log.changedByUserId">{{ user.first_name + ' ' + user.last_name }}</a></div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer"></div>
</ng-template>
