import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {Profile} from "@shared/models/profile";
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {defaultDebounceTime, getLogoPath} from '@app/shared-module/utils/utils';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {debounceTime, filter, first, switchMap} from 'rxjs/operators';
import {LangInfo, LanguageService} from '@app/core-module/services/language.service';
import {ReCaptchaV3Service} from "ng-recaptcha";
import {of} from 'rxjs/internal/observable/of';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TailwindModalComponent} from "@app/design-module/components/tailwind-modal/tailwind-modal.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routerTransition()]
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('popup') popup;
  @ViewChild('modalComponent') modalComponent: TailwindModalComponent;

  currentProfile: Observable<Profile>;
  readingCurrentProfile: Observable<boolean>;

  showInvalidCredentialsMessage = false;
  showErrorOnLoggingMessage = false;
  showInvalidTfaCode = false;
  logging = false;

  tfaFlag = false;

  formGroup: FormGroup;
  logoPath: string;
  currentLang$: Observable<LangInfo>;

  passwordVisible = false;

  constructor(private currentProfileState: CurrentProfileState,
              private authenticationService: AuthenticationService,
              private router: Router,
              private modalService: NgbModal,
              private languageService: LanguageService,
              private recaptchaV3Service: ReCaptchaV3Service) {
    this.logoPath = getLogoPath();
    this.currentProfile = this.currentProfileState.getCurrentProfile();
    this.readingCurrentProfile = this.currentProfileState.getReadingCurrentProfile().pipe(debounceTime(defaultDebounceTime));
    this.currentLang$ = languageService.getSelectedLanguage$();
  }

  ngOnInit() {
    document.body.classList.add('recaptcha-protected');
    this.currentProfile.pipe(
      filter(it => it !== null),
      first()
    ).subscribe(profile => {
      if (profile.id) {
        if (profile.user != null && profile.user.isAdminFI) {
          this.router.navigate(['/admin']).then(() => this.logging = false);
        } else if (profile.user != null) {
          this.router.navigate(['/fi']).then(() => this.logging = false);
        } else {
          this.router.navigate(['/profile']).then(() => this.logging = false);
        }
      }
    });
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      tfaCode: new FormControl('')
    });
  }

  ngAfterViewInit() {
    console.log('Opening modal...');
    this.modalComponent.open(this.popup);
    console.log('Opened modal...');
  }

  ngOnDestroy() {
    document.body.classList.remove('recaptcha-protected');
  }

  login() {
    this.logging = true;
    this.showInvalidCredentialsMessage = false;
    this.showErrorOnLoggingMessage = false;
    this.showInvalidTfaCode = false;

    const initiator = environment.recaptcha.enabled ? this.recaptchaV3Service.execute('login') : of(undefined);
    initiator.pipe(
      switchMap(token => this.authenticationService.login(
        this.formGroup.get('email').value,
        this.formGroup.get('password').value,
        this.formGroup.get('tfaCode').value,
        token // pass the reCAPTCHA token
      ))
    ).subscribe(
      (res: any) => {
        // Handle successful login
        if (res.status === 206) {
          this.tfaFlag = true;
        }
        this.logging = false;
      },
      (error) => {
        // Handle login errors
        this.logging = false;
        if (error.status === 401) {
          this.showInvalidCredentialsMessage = true;
        } else if (error.status === 400) {
          this.showInvalidTfaCode = true;
        } else {
          this.showErrorOnLoggingMessage = true;
        }
      }
    );
  }

  setLanguage(languageCode: string) {
    this.languageService.setLanguage(languageCode);
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

}

