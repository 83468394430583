import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {map, merge} from 'rxjs/operators';

export interface LangInfo {
  language: string;
  flag: string;
  code: string;
}

@Injectable()
export class LanguageService {
  selected = '';

  constructor(private translate: TranslateService) { }

  setInitialLanguage() {
    let language = this.translate.getBrowserLang();
    if (language.toLowerCase() !== 'es' && language.toLowerCase() !== 'en') {
      language = 'en'; // only en and es are supported for now
    }
    this.translate.use(language);
    //  TODO store previous selected language somewhere and set it here for beter user experience. Call this method in app.component.
  }

  setLanguage(language: string) {
    this.translate.use(language);
    this.selected = language;
  }

  getLanguageText(langCode: string): string {
    for (const language of this.getLanguages()) {
      if (language.value === langCode) {
        return language.text;
      }
    }
  }

  private getLanguageFlag(langCode: string): string {
    for (const language of this.getLanguages()) {
      if (language.value === langCode) {
        return language.img;
      }
    }
  }

  public getSelectedLanguage$(): Observable<LangInfo> {
    return of({
      language: this.getLanguageText(this.translate.currentLang),
      code: this.translate.currentLang,
      flag: this.getLanguageFlag(this.translate.currentLang)
    }).pipe(
      merge(this.translate.onLangChange.pipe(
          map(it => ({
            language: this.getLanguageText(it.lang),
            code: it.lang,
            flag: this.getLanguageFlag(it.lang)
          }))
        )
      )
    );
  }

  getLanguages(): Array<{text: string, value: string, img: string}> {
    return [
      {text: 'English', value: 'en', img: '../../../assets/flags/americanFlag.svg'},
      {text: 'Español', value: 'es', img: '../../../assets/flags/spanishFlag.svg'},
    ];
  }


}

