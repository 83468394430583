<div [@routerTransition] class="relative bg-dark flex flex-col min-h-screen text-base">

  <img src="assets/images/loginBackground.svg" class="absolute w-full h-full object-cover">

<!--  Navbar-->
  <div class="flex text-off-white z-10 w-full justify-between items-center py-9 px-12">
    <div class="flex items-center gap-3">
      <img [src]="logoPath" class="w-[180px]"/>
      <span class="border border-brand h-12"></span>
      <div class="font-medium">{{ 'root.login: Redefining Borderless Banking' | translate }}</div>
    </div>
    <div class="flex items-center gap-3">
      <span class="font-medium">{{ 'root.login: Change language' | translate }}</span>
      <div class="flex">
        @if (currentLang$ | async; as currentLang) {
          <div class="col-auto p-3">
            <button class="flex items-center gap-2 border-t-2 pt-1" (click)="setLanguage('en')"
                    [ngClass]="{'border-brand': currentLang.code === 'en', 'border-dark': currentLang.code !== 'en'}">
              <img src="assets/flags/americanFlag.svg" height="14px" width="20px"/>
            </button>
          </div>
          <div class="col-auto p-3">
            <button class="flex items-center space-x-2 text-theme text-12 border-t-2 pt-1" (click)="setLanguage('es')"
                    [ngClass]="{'border-brand': currentLang.code === 'es', 'border-dark': currentLang.code !== 'es'}">
              <img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/>
            </button>
          </div>
        }
      </div>
    </div>

  </div>
<!--  Navbar-->

  <div class="flex flex-1 h-screen items-center justify-center z-10">
    <div class="w-full max-w-md flex flex-col">
      <span class="text-center pb-8 text-off-white">{{ 'root.forgot-password: Forgot Password' | translate }}</span>
      <span
        class="block w-full h-[1px] bg-[linear-gradient(to_right,_theme(colors.brand)_2px,_transparent_2px)] bg-bottom bg-[length:9px_2px] bg-repeat-x"></span>
      <form role="form" [formGroup]="formGroup" *ngIf="!forgotPasswordSent">
        <div class="mt-8 flex flex-col gap-3">
          <div class="form-group justify-content-center">
            <div class="w-full">
              <input #email type="text" class="rounded-lg w-full placeholder-gray-500 p-3" formControlName="email"
                     placeholder="{{'root.login: Enter email address' | translate}}">
              <bfv-messages [ngStyle]="{'display':'none'}"></bfv-messages>

            </div>
          </div>

          <button class="btn-auth"
                  [disabled]="!formGroup.valid" (click)="resetPassword()"
                  [ngClass]="{'running': (working | async),
                              'bg-gray-500': formGroup.invalid,
                              'bg-brand': formGroup.valid}">
            {{ 'root.forgot-password: Reset Password' | translate }}
            <div class="ld ld-ring ld-spin"></div>
          </button>
        </div>

      </form>

      <!--      Card-->
      <div class="text-off-white mt-6 gap-3 flex flex-col" *ngIf="forgotPasswordSent">
        <div
          class="font-semibold">{{ 'root.forgot-password: Check your email, a reset link has been sent. Go to the' | translate }}
          <a class="text-brand"
             [routerLink]="'/auth/login'">{{ 'root.forgot-password: login page' | translate }}</a>.
        </div>
        <div>{{ 'root.forgot-password: You didn\'t get an email?' | translate }}</div>
        <div>
          <button class="btn-auth bg-brand text-black" [disabled]="!formGroup.valid"
                  (click)="resetPassword()"
                  [ngClass]="{'running': (working | async)}">
            {{ 'root.forgot-password: Resend' | translate }}
            <!--            <div class="ld ld-ring ld-spin"></div>-->
          </button>
        </div>
      </div>
      <!--      Card-->

      <a [routerLink]="'/auth/login'" *ngIf="!forgotPasswordSent"
         class="btn-auth mt-3 text-off-white bg-black">
        {{ 'root.forgot-password: Go Back' | translate }}
      </a>

    </div>
  </div>
</div>

