import {Component, Input} from '@angular/core';
import {routerTransition} from "@app/shared-module/utils/router.animations";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AnswerLog} from "@shared/models/question-definition";

@Component({
  selector: 'questionnaire-changes-modal',
  templateUrl: './questionnaire-changes-modal.component.html',
  styleUrls: ['./questionnaire-changes-modal.component.scss'],
  animations: [routerTransition()]
})
export class QuestionnaireChangesModalComponent {
  @Input()
  answerLogs: AnswerLog[];

  constructor(
    private modalService: NgbModal
  ) {}

  propertyMapping: { [key: string]: string } = {
    'fullLegalName': "admin.changes-logs-modal: Full Legal Name",
    'typeOfBusiness': "admin.changes-logs-modal: Type of Business",
    'typeOfEntity': "admin.changes-logs-modal: Type of Entity",
    'registry': "admin.changes-logs-modal: Registry",
    'isOwnerOfWhichEntity': "admin.changes-logs-modal: Is Owner of Which Entity",
    'street': "admin.changes-logs-modal: Street",
    'city': "admin.changes-logs-modal: City",
    'country': "admin.changes-logs-modal: Country",
    'zip': "admin.changes-logs-modal: Zip",
    'ownerType': "admin.changes-logs-modal: Owner Type",
    'ownerFullName': "admin.changes-logs-modal: Owner Full Name",
    'howManyOwns': "admin.changes-logs-modal: How Many % Owns",
    'email': "admin.changes-logs-modal: Email",
    'phone': "admin.changes-logs-modal: Phone",
    'aliases': "admin.changes-logs-modal: Aliases",
    'dateOfBirth': "admin.changes-logs-modal: Date of Birth",
    'state': "admin.changes-logs-modal: State",
    'nationality': "admin.changes-logs-modal: Nationality",
    'residency': "admin.changes-logs-modal: Residency",
    'isPep': "admin.changes-logs-modal: Is PEP",
    'pepInformation': "admin.changes-logs-modal: PEP Information",
    'profession': "admin.changes-logs-modal: Profession",
    'idUploadFilenames': "admin.changes-logs-modal: Uploaded File",
    'idDocType': "admin.changes-logs-modal: Document Type",
    'copyOfArticlesOfIncorporationFilenames': "admin.changes-logs-modal: Added File",
    'otherDocumentsInKYCFilenames': "admin.changes-logs-modal: Added Other File",
    'copyOfIDFilenames': "admin.changes-logs-modal: Added File",
    'idDocNumber': "admin.changes-logs-modal: Document Number",
    'dateOfIssue': "admin.changes-logs-modal: Date of Issue",
    'dateOfExpire': "admin.changes-logs-modal: Date of Expire",
    'countryOfIssue': "admin.changes-logs-modal: Country of Issue",
  };

  open(content) {
    this.modalService.open(content, {
      size: 'xl',
      windowClass: 'custom-modal-height'
    }).result.then(() => {
    }, (reason) => {
      // do nothing
    });
  }

}
