import {Component, Input, OnInit} from '@angular/core';
import {dropDown, rotateArrow, routerTransition} from '@app/shared-module/utils/router.animations';
import {Observable} from 'rxjs/internal/Observable';
import {map, scan, startWith, switchMap} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {RoutingUtilsService} from '@app/core-module/services/routing-utils.service';

@Component({
  selector: 'design-navigation-item-parent',
  templateUrl: './navigation-item-parent.component.html',
  styleUrls: ['./navigation-item-parent.component.scss'],
  animations: [routerTransition(), dropDown(), rotateArrow()]
})
export class NavigationItemParentComponent implements OnInit {

  @Input() title: string;
  @Input() urlPrefix: string;

  isActiveGroup$: Observable<boolean>;
  isExpanded$: Observable<boolean>;

  private toggleUserClicks$ = new Subject<void>();

  constructor(private routingUtilsService: RoutingUtilsService) {

  }

  ngOnInit() {

    this.isActiveGroup$ = this.routingUtilsService.getCurrentUrl().pipe(
      map(url => url.startsWith(this.urlPrefix))
    );

    this.isExpanded$ = this.isActiveGroup$.pipe(
      switchMap(isActiveGroup => this.toggleUserClicks$.pipe(
        startWith(null),
        scan((acc) => !acc, !isActiveGroup),
      )),
    );
  }

  toggleExpanded() {
    this.toggleUserClicks$.next();
  }
}
