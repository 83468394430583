@if (formGroup) {
  <div [formGroup]="formGroup">
    <label>
      {{label}}
      @if (currencyType) {
        <span> {{ currencyType }}</span>
      }
      <span *ngIf="required" class="required-field"></span>
    </label>
    @if (useAutocomplete) {
      <input [formControlName]="fControlName" [type]="type" class="field"
             (focusout)="formGroup.get(fControlName).markAsDirty()"
             [value]="value"
             [ngbTypeahead]="ngbTypeahead"
             [inputFormatter]="inputFormatter"
             [resultFormatter]="resultFormatter"
             (selectItem)="selectedItem($event.item)"
             [placeholder]="placeHolder ? placeHolder : ''"
             [ngClass]="{'input-required-error': element.invalid && (element.dirty || element.touched)}"
             (input)="isTypingUser()"
             #inputElement>
    } @else {
      <input [formControlName]="fControlName" [type]="type" class="field"
             (focusout)="formGroup.get(fControlName).markAsDirty()"
             [value]="value"
             [placeholder]="placeHolder ? placeHolder : ''"
             [ngClass]="{'input-required-error': element.invalid && (element.dirty || element.touched)}"
             (input)="isTypingUser()"
             #inputElement>
    }
  </div>
} @else if (!disabled) {
  <div>
    <label>
      {{label}}
      @if (currencyType) {
        <span> {{ currencyType }}</span>
      }
      <span *ngIf="required" class="required-field"></span>
    </label>
    <input [type]="type" [value]="value" [placeholder]="placeHolder" #inputElement class="field">
  </div>
} @else {
  <div>
    <label>
      {{label}}
      @if (currencyType) {
        <span> {{ currencyType }}</span>
      }
      <span *ngIf="required" class="required-field"></span>
    </label>
    <input [type]="type" [value]="value" [placeholder]="placeHolder" #inputElement class="field" [disabled]="disabled">
  </div>
}
