<div *ngIf="countries | async as countries" [formGroup]="formGroup">
  <design-tailwind-input-select [label]="countryLabel"
                                [fControlName]="countryControlName"
                                [required]="required">
    <option [value]="null">{{ 'shared.add-person-modal: Select' | translate }}</option>
    <option *ngFor="let country of (countries | orderBy : 'Country')" [value]="country['Alpha-3 code']">{{ country['Country'] }}</option>
  </design-tailwind-input-select>
  <div *ngIf="showStateInput && states.length > 0">
    <design-tailwind-input-select [label]="stateLabel"
                                  [fControlName]="stateControlName">
      <option [value]="null">{{ 'shared.add-person-modal: Select' | translate }}</option>
      <option *ngFor="let state of (states | orderBy : 'name')" [value]="state.abbreviation">{{ state.name }}</option>
    </design-tailwind-input-select>
  </div>
</div>
