import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {FormGroup, FormGroupDirective} from '@angular/forms';

@Component({
  selector: "design-tailwind-checkbox",
  templateUrl: "./tailwind-checkbox.component.html",
  styleUrls: ["./tailwind-checkbox.component.scss"],
  animations: [routerTransition()]
})
export class TailwindCheckboxComponent implements OnInit {

  @Input() checked = false;
  @Input() disabled = false;
  @Input() label: string;
  @Input() fControlName: string;
  @Output() onValueChange = new EventEmitter<boolean>();
  @ViewChild('checkboxElement') checkboxElement: ElementRef;

  formGroup: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  ngOnInit() {
    this.formGroup = this.rootFormGroup.control;
  }

  onCheckedBoxChange(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.onValueChange.emit(isChecked);
  }
}


