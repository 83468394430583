
<div *ngIf="isModalRendered" [ngClass]="shouldBeVisible ? 'modalOpen' : 'modalClose'" #modalRef>
  <div class="bg-zinc-50/90 fixed inset-0 transition-opacity"></div>
  <div class="fixed inset-0 overflow-y-auto">
    <div class="outside flex min-h-full items-center justify-center" (click)="clickOutside($event)">
      <div class="outside w-full max-w-3xl p-4 sm:p-6 lg:py-8">
        <div class="modal" [ngClass]="shouldBeVisible ? 'modal-scale-forwards' : 'modal-scale-backwards'">
          <div (click)="close()" class="absolute top-6 right-5">
            <button type="button" class="-m-3 flex-none p-3 opacity-20 hover:opacity-40">
              <span class="hero-x-mark-solid h-5 w-5"></span>
            </button>
          </div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
