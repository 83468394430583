<div class="relative flex flex-col min-h-screen bg-dark text-base" [@routerTransition]>

  <img src="assets/images/loginBackground.svg" class="absolute w-full h-full object-cover z-0">

  <!--  Navbar-->
  <div class="flex text-off-white z-10 w-full justify-between items-center py-9 px-12">
    <div class="flex items-center gap-3">
      <img [src]="logoPath" class="user-avatar w-[180px]"/>
      <span class="border border-brand h-12"></span>
      <div class="font-medium">{{ 'root.login: Redefining Borderless Banking' | translate }}</div>
    </div>
    <div class="flex items-center gap-3">
      <span class="font-medium">{{ 'root.login: Change language' | translate }}</span>
      @if(currentLang$ |async; as currentLang){
      <div class="flex">
        <div class="col-auto p-3">
          <button class="flex items-center space-x-2 text-theme text-12 border-t-2 pt-1" (click)="setLanguage('en')"
                  [ngClass]="{'border-brand': currentLang.code === 'en', 'border-dark': currentLang.code !== 'en'}">
            <img src="assets/flags/americanFlag.svg" height="14px" width="20px"/>
          </button>
        </div>
        <div class="col-auto p-3">
          <button class="flex items-center space-x-2 text-theme text-12 border-t-2 pt-1" (click)="setLanguage('es')"
                  [ngClass]="{'border-brand': currentLang.code === 'es', 'border-dark': currentLang.code !== 'es'}">
            <img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/>
          </button>
        </div>
      </div>
      }
    </div>
  </div>
  <!--  Navbar-->


  <div class="flex flex-col flex-1 h-screen items-center justify-center z-10 text-off-white">

    <div class="w-full max-w-md flex flex-col">
      <span
        class="text-center pb-8 text-off-white">{{ 'root.forgot-password: Activate Your Password' | translate }}</span>
      <span
        class="block w-full h-[1px] bg-[linear-gradient(to_right,_theme(colors.brand)_2px,_transparent_2px)] bg-bottom bg-[length:9px_2px] bg-repeat-x"></span>

      <form *ngIf="showActivationField" role="form" [formGroup]="formGroup">
        <div class="mt-8 flex flex-col gap-3 mb-3">
          <div class="w-full">
            <input type="text" class="rounded-lg w-full placeholder-gray-500 p-3 text-black"
                   formControlName="code" (keypress)="hideErrorMessage()"
                   placeholder="{{ 'root.activate-account: Activation Code' | translate }}"
                   pattern="^\d{6}$"
            >
            <div class="text-danger" *ngIf="showErrorMessage">{{ errorMessage }}</div>
          </div>
        </div>
        <button class="btn-auth text-black" [disabled]="!formGroup.valid"
                [ngClass]="{'bg-gray-500': formGroup.invalid,
                            'bg-brand': formGroup.valid}"
                (click)="activate()">{{ 'root.activate-account: Activate' | translate }}
        </button>
      </form>
      <div class="btn-auth bg-black text-white mt-3">
        <a [routerLink]="'/auth/login'">{{ 'root.forgot-password: Go Back' | translate }}</a>
      </div>

      <div class="text-center mt-5" *ngIf="showActivating">
        {{ 'root.activate-account: Activating your account. Please wait.' | translate }}
      </div>

      <div class="text-center mt-5" *ngIf="showActive">
        <div *ngIf="currentProfile | async; else not_logged">
          {{ 'root.activate-account: Your account is active. Go to your' | translate }} <a
          class="font-semibold text-brand"
          [routerLink]="'/profile'">{{ 'root.activate-account: profile page' | translate }}</a>.
        </div>
        <ng-template #not_logged>
          <div #not_logged>
            {{ 'root.activate-account: Your account is active. Go to the' | translate }} <a
            class="font-semibold text-brand"
            [routerLink]="'/auth/login'">{{ 'root.activate-account: login page' | translate }}</a>.
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

