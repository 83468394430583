import {Injectable} from "@angular/core";
import {ApiService} from "@app/core-module/services/api.service";
import {RxUtilsService} from "@app/core-module/services/rx-utils.service";
import {CurrentBankState} from "@app/core-module/states/current.bank.state";
import {Person} from "@shared/models/person";
import {Observable} from "rxjs";
import {distinctUntilChanged, filter, merge, tap} from "rxjs/operators";
import {Subject} from "rxjs";
import {FiKycProfilesFilter} from "@shared/models/non-db/fi-kyc-profiles-filter";

@Injectable()
export class PersonsState {
  private persons: Observable<Person[]>;
  private reading: Observable<boolean>;

  private read$ = new Subject<boolean>();

  constructor(
    private apiService: ApiService,
    private currentBankState: CurrentBankState,
    private rxUtilsService: RxUtilsService
  ) {
    const initiator = this.currentBankState.getCurrentBankId().pipe(
      filter((it) => it != null),
      merge(this.read$)
    );
    this.persons = this.rxUtilsService.createGetStateValue(
      initiator,
      this.apiService.getPersons(<FiKycProfilesFilter>{}),
      "Error while reading the list of persons!",
      []
    );
    this.reading = this.rxUtilsService.createReadingInfo(
      initiator,
      this.persons
    );
  }

  getPersons(): Observable<Person[]> {
    return this.persons;
  }

  public addPerson(person: Person, options: {toDuplicate: boolean}): Observable<Person> {
    return this.apiService
      .addPerson(person, options)
      .pipe(tap(() => this.read$.next(true)));
  }

  public addPersonsFromExcel(file: any): Observable<Person[]> {
    return this.apiService
      .addPersonsFromExcel(file)
      .pipe(tap(() => this.read$.next(true)));
  }

  public editPerson(person: Person): Observable<Person> {
    return this.apiService
      .editPerson(person)
      .pipe(tap(() => this.read$.next(true)));
  }

  public clonePerson(person: Person): Observable<Person> {
    return this.apiService
      .clonePerson(person)
      .pipe(tap(() => this.read$.next(true)));
  }

  getReading(): Observable<boolean> {
    return this.reading.pipe(distinctUntilChanged());
  }

  public reload() {
    this.read$.next(true);
  }
  public editOrClonePerson(person: Person): Observable<Person> {
    return this.apiService.editOrClonePerson(person)
      .pipe(tap(() => this.read$.next(true)));
  }
}
