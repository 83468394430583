import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'design-tailwind-add-record-button',
  templateUrl: './tailwind-add-record-button.component.html',
  styleUrl: './tailwind-add-record-button.component.scss'
})
export class TailwindAddRecordButtonComponent {

  @Input() label: string;
  @Output() click: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  emitSignal() {
    this.click.emit();
  }
}
