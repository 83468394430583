import {filter, map, startWith} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class RoutingUtilsService {

  constructor(private router: Router) {
  }

  public getCurrentUrl() {
    return this.router.events.pipe(
      filter(it => it instanceof NavigationEnd),
      map(it => (it as NavigationEnd).url),
      startWith(this.router.url)
    );
  };
}
