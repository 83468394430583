import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {TailwindTableComponent} from '@app/design-module/components/tailwind-table/tailwind-table.component';
import {TailwindModalComponent} from '@app/design-module/components/tailwind-modal/tailwind-modal.component';
import {TailwindDatePickerComponent} from '@app/design-module/components/tailwind-date-picker/tailwind-date-picker.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {TailwindCheckboxComponent} from '@app/design-module/components/tailwind-checkbox/tailwind-checkbox.component';
import {TailwindInputComponent} from '@app/design-module/components/tailwind-input/tailwind-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TailwindInputSelectComponent} from '@app/design-module/components/tailwind-input-select/tailwind-input-select.component';
import {
  TailwindInputSelectCountryComponent
} from '@app/design-module/components/tailwind-input-select-country/tailwind-input-select-country.component';
import {NgArrayPipesModule} from 'ngx-pipes';
import {NgBootstrapFormValidationModule} from '@mingleats/ng-bootstrap-form-validation';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {TooltipDirective} from '@app/design-module/directives/tailwind-tooltip.directive';
import {NavigationItemComponent} from '@app/design-module/components/navigation/navigation-item/navigation-item.component';
import {
  NavigationItemParentComponent
} from '@app/design-module/components/navigation/navigation-item-parent/navigation-item-parent.component';
import {NavigationItemChildComponent} from '@app/design-module/components/navigation/navigation-item-child/navigation-item-child.component';
import {
  TailwindAddRecordButtonComponent
} from "@app/design-module/components/tailwind-add-record-button/tailwind-add-record-button.component";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";

const TailwindComponents = [
  TailwindTableComponent,
  TailwindModalComponent,
  TailwindDatePickerComponent,
  TailwindCheckboxComponent,
  TailwindInputComponent,
  TailwindInputSelectComponent,
  TailwindInputSelectCountryComponent,
  TailwindAddRecordButtonComponent,
  TooltipDirective
];

const NavigationComponents = [
  NavigationItemComponent,
  NavigationItemParentComponent,
  NavigationItemChildComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        NgArrayPipesModule,
        NgBootstrapFormValidationModule,
        FaIconComponent,
        NgbTypeahead,
    ],
  declarations: [
    ...NavigationComponents,
    ...TailwindComponents
  ],
  providers: [],
  exports: [
    ...NavigationComponents,
    ...TailwindComponents
  ]
})
export class DesignModule {

}
