import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {sleep} from '@shared/utils/utils';

@Component({
  selector: 'design-tailwind-modal',
  templateUrl: './tailwind-modal.component.html',
  styleUrls: ['./tailwind-modal.component.scss'],
  animations: [routerTransition()]
})
export class TailwindModalComponent implements OnInit, AfterViewInit, OnDestroy {

  isModalRendered = false;
  shouldBeVisible = true;

  private onSubmit: () => Promise<void> | void;
  private onClose: () => Promise<void> | void;
  @ViewChild('modalRef') modalRef: ElementRef;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void { // TODO this needs to be further tested in case of event emit it might brake but needs to be further tested
    const ref = this.elementRef.nativeElement as HTMLElement;
    this.renderer.appendChild(document.body, ref);
  }

  ngOnDestroy(): void {
    const ref = this.elementRef.nativeElement as HTMLElement;
    this.renderer.removeChild(document.body, ref);
  }

  open(callbacks: {
    onSubmit?: () => Promise<void> | void,
    onClose?: () => Promise<void> | void
  }) {
    this.onSubmit = callbacks.onSubmit || (async () => null);
    this.onClose = callbacks.onClose || (async () => null);

    if (this.isModalRendered) {
      this.isModalRendered = false;
    }
    this.disableScroll();
    setTimeout(() => {
      this.isModalRendered = true;
      this.shouldBeVisible = true;
    });
  }

  protected async clickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (targetElement.classList.contains('outside')) {
      await this.close();
    }
  }

  async close() {
    this.shouldBeVisible = false;
    await Promise.all([this.onClose(), sleep(400)]);
    this.isModalRendered = false;
    this.enableScroll();
  }

  async submit(dontClose = false) {
    if (dontClose) {
      await this.onSubmit();
    } else {
      await Promise.all([this.onSubmit(), this.close()]);
    }
  }

  private disableScroll() {
    document.body.style.overflow = 'hidden';
  }

  private enableScroll() {
    document.body.style.overflow = 'auto';
  }
}


