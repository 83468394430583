import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {OperatorFunction} from 'rxjs/internal/types';

@Component({
  selector: 'design-tailwind-input',
  templateUrl: './tailwind-input.component.html',
  styleUrls: ['./tailwind-input.component.scss'],
  animations: [routerTransition()],
})
export class TailwindInputComponent implements OnInit {

  @Input() fControlName: string;
  @Input() label: string;
  @Input() required = false;
  @Input() placeHolder: string;
  @Input() type = 'text';
  @Input() value = null;
  @Input() disabled = false;
  @Input() ngbTypeahead: OperatorFunction<string, readonly any[]>;
  @Input() inputFormatter: (item: any) => string;
  @Input() resultFormatter: (item: any) => string;
  @Input() currencyType: string;
  @Input() useAutocomplete = false;

  formGroup: FormGroup;

  @Output() onAutocompleteItemSelected = new EventEmitter<any>();
  @Output() isUserTyping = new EventEmitter<boolean>();

  @ViewChild('inputElement') inputElement: ElementRef;

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  ngOnInit() {
    this.formGroup = this.rootFormGroup.control;
  }

  selectedItem(item: any) {
    this.onAutocompleteItemSelected.emit(item);
  }

  get element() {
    return this.formGroup.get(this.fControlName);
  }

  isTypingUser() {
    this.isUserTyping.emit(true);
  }
}
