<button (click)="emitSignal()" class="inline-flex gap-x-1 py-2 px-3 rounded-lg text-sm font-semibold phx-submit-loading:opacity-75 loading-6 bg-brand text-dark hover:bg-brand/80">
  <span class="relative h-4 w-4 -top-0.5">
    <svg class="inline-block" data-phx-id="m16-phx-GA18kpQdlC-cdQFx" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g id="loupe">
        <mask id="mask0_4015_62405" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <rect id="Bounding box" x="0.941406" y="0.5" width="14.1176" height="15" fill="#D9D9D9"></rect>
        </mask>
        <g mask="url(#mask0_4015_62405)">
          <path id="loupe_2" d="M7.41228 11.125H8.58875V8.625H10.9417V7.375H8.58875V4.875H7.41228V7.375H5.05934V8.625H7.41228V11.125ZM8.00052 14.25C7.18679 14.25 6.42209 14.0859 5.7064 13.7578C4.99071 13.4297 4.36816 12.9844 3.83875 12.4219C3.30934 11.8594 2.89022 11.1979 2.5814 10.4375C2.27258 9.67708 2.11816 8.86458 2.11816 8C2.11816 7.13542 2.27258 6.32292 2.5814 5.5625C2.89022 4.80208 3.30934 4.14062 3.83875 3.57812C4.36816 3.01562 4.99071 2.57031 5.7064 2.24219C6.42209 1.91406 7.18679 1.75 8.00052 1.75C8.81424 1.75 9.57895 1.91406 10.2946 2.24219C11.0103 2.57031 11.6329 3.01562 12.1623 3.57812C12.6917 4.14062 13.1108 4.80208 13.4196 5.5625C13.7285 6.32292 13.8829 7.13542 13.8829 8V13C13.8829 13.3438 13.7677 13.638 13.5373 13.8828C13.3069 14.1276 13.0299 14.25 12.7064 14.25H8.00052ZM8.00052 13C9.31424 13 10.427 12.5156 11.3388 11.5469C12.2505 10.5781 12.7064 9.39583 12.7064 8C12.7064 6.60417 12.2505 5.42188 11.3388 4.45313C10.427 3.48438 9.31424 3 8.00052 3C6.68679 3 5.57405 3.48438 4.66228 4.45313C3.75052 5.42188 3.29463 6.60417 3.29463 8C3.29463 9.39583 3.75052 10.5781 4.66228 11.5469C5.57405 12.5156 6.68679 13 8.00052 13Z"></path>
        </g>
      </g>
    </svg>
  </span>
  <span>
    {{ label }}
    <div class="loader"></div>
  </span>
</button>
