import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {FormGroup, FormGroupDirective, Validators} from '@angular/forms';

@Component({
  selector: "design-tailwind-input-select",
  templateUrl: "./tailwind-input-select.component.html",
  styleUrls: ["./tailwind-input-select.component.scss"],
  animations: [routerTransition()],
})
export class TailwindInputSelectComponent implements AfterViewInit {
  @Input() fControlName: string;
  @Input() label: string;
  @Input() required = false;
  @ViewChild('selectElement') selectElement: ElementRef;

  formGroup: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  ngOnInit() {
    this.formGroup = this.rootFormGroup.control;
  }


  ngAfterViewInit(): void {
    this.setDefaultValue();
  }

  // ! This method only works with ngValue
  private setDefaultValue() {
    const controlValue = this.formGroup.get(this.fControlName).value;
    const options = this.selectElement.nativeElement.querySelectorAll('option');
    for (const option of options) {
      if (option.getAttribute('ng-reflect-ng-value') === null && (
        controlValue === null ||
        this.formGroup.get(this.fControlName).hasValidator(Validators.required) && this.formGroup.get(this.fControlName).value === '')
      ) {
        option.selected = true;
        break;
      }
      if (option.getAttribute('ng-reflect-ng-value') === String(controlValue)) {
        option.selected = true;
        break;
      }
    }
  }

  get element() {
    return this.formGroup.get(this.fControlName);
  }
}
