<div class="relative flex flex-col min-h-screen bg-dark text-base">

  <img src="assets/images/loginBackground.svg" class="absolute w-full h-full object-cover z-0">

  <!--  Navbar-->
  <div class="flex text-off-white z-10 w-full justify-between items-center py-9 px-12">
    <div class="flex items-center gap-3">
      <img [src]="logoPath" class="user-avatar w-[180px]"/>
      <span class="border border-brand h-12"></span>
      <div class="font-medium">{{ 'root.login: Redefining Borderless Banking' | translate }}</div>
    </div>
    <div class="flex items-center gap-3">
      <span class="font-medium">{{ 'root.login: Change language' | translate }}</span>
      @if(currentLang$ | async; as currentLang){
      <div class="flex">
        <div class="col-auto p-3">
          <button class="flex items-center space-x-2 text-theme text-12 border-t-2 pt-1" (click)="setLanguage('en')"
                  [ngClass]="{'border-brand': currentLang.code === 'en', 'border-dark': currentLang.code !== 'en'}">
            <img src="assets/flags/americanFlag.svg" height="14px" width="20px"/>
          </button>
        </div>
        <div class="col-auto p-3">
          <button class="flex items-center space-x-2 text-theme text-12 border-t-2 pt-1" (click)="setLanguage('es')"
                  [ngClass]="{'border-brand': currentLang.code === 'es', 'border-dark': currentLang.code !== 'es'}">
            <img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/>
          </button>
        </div>
      </div>
      }
    </div>
  </div>
  <!--  Navbar-->


  <div class="flex flex-col flex-1 h-screen items-center justify-center z-10 text-off-white">

     <span
       class="text-center pb-8 text-off-white max-w-lg">{{ 'root.tfa-setup: Two-Factor Authentication' | translate }}</span>
    <span
      class="max-w-lg block w-full h-[1px] bg-[linear-gradient(to_right,_theme(colors.brand)_2px,_transparent_2px)] bg-bottom bg-[length:9px_2px] bg-repeat-x">
      </span>

    <div class="w-full max-w-lg flex flex-col" *ngIf="!successfullyInit">

      <div *ngIf="tfaSetupData != undefined">
        <form [formGroup]="tfaFormGroup" role="form">
          <div class="flex flex-col gap-3 mb-3 mt-8">
            <div class="font-medium">{{ 'root.tfa-setup: Step 1' | translate }}</div>
            <p>{{ 'root.tfa-setup: Download and install the' | translate }} <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
              class="text-brand font-semibold"
              target="blank">{{ 'root.tfa-setup: Google Authenticator' | translate }}</a> {{ 'root.tfa-setup: or' | translate }}
              <a href="https://authy.com/download/"
                 target="blank" class="text-brand font-semibold">{{ 'root.tfa-setup: Authy' | translate }}</a>
              {{ 'root.tfa-setup: app on your device.' | translate }}</p>
            <hr>
            <div class="font-medium">{{ 'root.tfa-setup: Step 2' | translate }}</div>
            <p>{{ 'root.tfa-setup: Open the authentication app and scan the QR code or enter the secret key.' | translate }}</p>
            <div class="flex justify-center mt-5">
              <img src="{{tfaSetupData.dataURL}}">
            </div>
            <br>
            <div>
              <span>{{ 'root.tfa-setup: Secret Key:' | translate }}</span>
              <span>{{ tfaSetupData.tempSecret }}</span>
            </div>
            <hr>
            <div class="font-medium">{{ 'root.tfa-setup: Step 3' | translate }}</div>
            <p>{{ 'root.tfa-setup: Enter the 6-digit verification code generated by the app.' | translate }}</p>
            <input type="text" formControlName="tfaCode" class="rounded-lg w-full p-3 placeholder-gray-500 text-black"
                   placeholder="{{'root.tfa-setup: Verification code' | translate}}" pattern="^\d{6}$">
          </div>
          <div class="flex justify-between mt-5 gap-7">
            <button class="btn-auth bg-red-500" type="button"
                    (click)="logout()">{{ 'root.tfa-setup: Logout' | translate }}
            </button>
            <button type="submit" class="btn-auth text-dark"
                    (click)="tfaSetupVerify()" [disabled]="tfaFormGroup.invalid" [ngClass]="{'bg-gray-500' : tfaFormGroup.invalid, 'bg-brand':tfaFormGroup.valid}"
            >{{ 'root.tfa-setup: Enable' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="successfullyInit" class="mt-8 max-w-lg w-full">
      <div *ngIf="currentProfile | async as profile">
        <button class="btn-auth bg-brand text-black"
                *ngIf="profile.user && profile.user.isAdminFI; else notAdminFi" [routerLink]="'/admin'">
          {{ 'root.tfa-setup: Click here to continue' | translate }}
        </button>
        <ng-template #notAdminFi>
          <button class="btn-auth bg-brand text-black"
                  [routerLink]="'/fi'">{{ 'root.tfa-setup: Click here to continue' | translate }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
