<div [@routerTransition] class="relative flex flex-col min-h-screen bg-dark text-base">

  <img src="assets/images/loginBackground.svg" class="absolute w-full h-full object-cover z-0">

<!--  Navbar-->
  <div class="flex text-off-white z-10 w-full justify-between items-center py-9 px-12">
    <div class="flex items-center gap-3">
      <img [src]="logoPath" class="user-avatar w-[180px]"/>
      <span class="border border-brand h-12"></span>
      <div class="font-medium">{{ 'root.login: Redefining Borderless Banking' | translate }}</div>
    </div>
    <div class="flex items-center gap-3">
      <span class="font-medium">{{ 'root.login: Change language' | translate }}</span>
      @if(currentLang$ | async; as currentLang){
      <div class="flex">
        <div class="col-auto p-3">
          <button class="flex items-center space-x-2 text-theme text-12 border-t-2 pt-1" (click)="setLanguage('en')"
                  [ngClass]="{'border-brand': currentLang.code === 'en', 'border-dark': currentLang.code !== 'en'}">
            <img src="assets/flags/americanFlag.svg" height="14px" width="20px"/>
          </button>
        </div>
        <div class="col-auto p-3">
          <button class="flex items-center space-x-2 text-theme text-12 border-t-2 pt-1" (click)="setLanguage('es')"
                  [ngClass]="{'border-brand': currentLang.code === 'es', 'border-dark': currentLang.code !== 'es'}">
            <img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/>
          </button>
        </div>
      </div>
      }
    </div>
  </div>
<!--  Navbar-->

  <div class="flex flex-1 h-screen items-center justify-center z-10">
    <div class="w-full max-w-md flex flex-col">

      <span class="text-center pb-8 text-off-white">{{ 'root.new-password: New Password' | translate }}</span>
      <span
        class="block w-full h-[1px] bg-[linear-gradient(to_right,_theme(colors.brand)_2px,_transparent_2px)] bg-bottom bg-[length:9px_2px] bg-repeat-x"></span>


      <form role="form" *ngIf="token && !passwordChanged" [formGroup]="formGroup">
        <div class="mt-8 flex flex-col gap-3 mb-3">

          <div class="form-group">
            <div class="relative w-full flex items-center">
              <input #password type="{{passwordVisible? 'text': 'password'}}" class="rounded-lg w-full p-3"
                     formControlName="password" placeholder="{{ 'root.new-password: Password' | translate }}">
              <bfv-messages [ngStyle]="{'display':'none'}"></bfv-messages>
              <button type="button" class="absolute flex right-0 mr-3 text-[#4F7471]"
                      (click)="togglePasswordVisibility()">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5"
                     stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"/>
                  <path *ngIf="passwordVisible" stroke-linecap="round" stroke-linejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                  <path *ngIf="!passwordVisible" stroke-linecap="round" stroke-linejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"/>
                </svg>

              </button>
            </div>
          </div>
          <div class="form-group">
            <div class="relative w-full flex items-center">
              <input #verify type="{{passwordVisible? 'text': 'password'}}" class="rounded-lg w-full p-3 placeholder-gray-500"
                     formControlName="verify" placeholder="{{ 'root.new-password: Verify Password' | translate }}">
              <bfv-messages [ngStyle]="{'display':'none'}"></bfv-messages>
              <button id="btn2" type="button" class="absolute flex right-0 mr-3 text-[#4F7471]"
                      (click)="togglePasswordVisibility()">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5"
                     stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"/>
                  <path *ngIf="passwordVisible" stroke-linecap="round" stroke-linejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                  <path *ngIf="!passwordVisible" stroke-linecap="round" stroke-linejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"/>
                </svg>

              </button>

            </div>
            <div [hidden]="!formGroup.get('verify').touched || !formGroup.get('verify').hasError('MatchPassword')"
                 class="text-danger">{{ 'root.new-password: Password Don\'t Match' | translate }}
            </div>
          </div>
        </div>
        <button class="btn-auth" [disabled]="!formGroup.valid" (click)="changePassword()"
                [ngClass]="{'running': (working | async),'bg-gray-500': formGroup.invalid,
                                'bg-brand': formGroup.valid}">
          {{ isForgotPasswordPage ? ('root.new-password: Change Password' | translate) : ('root.new-password: Set Password' | translate) }}
        </button>
        <button class="btn-auth bg-black text-white mt-3">
          <a class="link text-14" [routerLink]="'/auth/login'">{{ 'root.forgot-password: Go Back' | translate }}</a>
        </button>
      </form>
      <div class="mt-5" *ngIf="token && passwordChanged">
        <div class="text-off-white text-center w-full font-semibold">
          {{ isForgotPasswordPage ? ('root.new-password: Your password is changed. Go to the' | translate) : ('root.new-password: You set your password. Go to the' | translate) }}
          <a class="text-brand" [routerLink]="'/auth/login'">{{ 'root.new-password: login page' | translate }}</a>.
        </div>
      </div>
      <shared-loading-bar *ngIf="!token"></shared-loading-bar>
    </div>
  </div>
</div>
