import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {TranslateService} from '@ngx-translate/core';
import moment, {Moment} from 'moment';

const MAX_DAYS_IN_ROW = 7;
const MAX_COLUMNS = 6;
const DATE_PICKER_ANIMATION_DURATION = 400;
const DATE_PICKER_DATE_ISO_FORMAT = moment.defaultFormat;

enum DatePickerState {
  SELECT_DAY,
  SELECT_MONTH,
  SELECT_YEAR
}
const YEAR_SELECT_LENGTH = 9;

@Component({
  selector: 'design-tailwind-date-picker',
  templateUrl: './tailwind-date-picker.component.html',
  styleUrls: ['./tailwind-date-picker.component.scss'],
  animations: [routerTransition()],
})
export class TailwindDatePickerComponent implements OnInit {

  constructor(private translationService: TranslateService,
              private rootFormGroup: FormGroupDirective) {

  }

  isDatePickerRendered = false;
  shouldDatePickerBeVisible = false;
  datePickerInputValue = '';
  datePickerState: DatePickerState = DatePickerState.SELECT_DAY;
  // animation for states
  shouldDatePickerStateBeVisible: boolean[] = [false, false, false];

  @ViewChild('datePickerMain') datePickerMainElement: ElementRef;
  @Input() forceWhiteTheme = false;
  @Input() dateInputClass = '';
  @Input() controlName: string;
  @Input() dateFormat = 'YYYY-MM-DD';
  @Output() onClose = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<void>();

  formGroup: FormGroup;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  yearsToRender: number[] = [];
  errorMessage: string | null;

  month: number;
  year: number;
  private lastYearSelected: number;

  protected readonly DatePickerState = DatePickerState;

  private formatDateString(dateValue: Moment) {
    moment.locale(this.translationService.currentLang);
    const isoDate = moment(dateValue, DATE_PICKER_DATE_ISO_FORMAT, true);
    return isoDate.format(this.dateFormat);
  }

  private formatMomentDateString(dateValue: moment.Moment) {
    moment.locale(this.translationService.currentLang);
    return dateValue.format(this.dateFormat);
  }

  ngOnInit() {
    this.formGroup = this.rootFormGroup.control;
    this.initDate();
    this.formGroup.get(this.controlName).valueChanges.subscribe(value => {
      if (value) {
        this.datePickerInputValue = this.formatDateString(value);
        this.setRenderYearsFrom(this.year, 1);
      } else {
        this.datePickerInputValue = '';
      }
    });
    this.resetDatePicker();
  }

  toggle() {
    this.isDatePickerRendered ? this.close() : this.open();
  }

  resetDatePicker() {
    this.setRenderYearsFrom(this.year - 1, 1);
    this.setDatePickerState(DatePickerState.SELECT_DAY);
  }

  open() {
    this.isDatePickerRendered = true;
    this.shouldDatePickerBeVisible = true;
    this.resetDatePicker();
  }

  close() {
    this.onClose.emit();
    this.shouldDatePickerBeVisible = false;
    setTimeout(() => {
      this.isDatePickerRendered = false;
      this.shouldDatePickerBeVisible = true;
      this.setDatePickerState(DatePickerState.SELECT_DAY);
    }, DATE_PICKER_ANIMATION_DURATION);
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: Event) {
    if (this.isDatePickerRendered && !this.datePickerMainElement.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  initDate() {
    let dateToSet = moment();
    if (this.formGroup.get(this.controlName).value) {
      dateToSet = moment(this.formGroup.get(this.controlName).value);
      this.datePickerInputValue = this.formatMomentDateString(dateToSet);
    }
    this.month = dateToSet.month();
    this.year = dateToSet.year();
    this.selectYear(this.year);
  }

  isToday(date: number) {
    const today = new Date();
    const d = new Date(this.year, this.month, date);
    return today.toDateString() === d.toDateString();
  }

  isSelected(date: number) {
    const selectedDate = new Date(this.formGroup.get(this.controlName).value);
    const d = new Date(this.year, this.month, date);
    return selectedDate.toDateString() === d.toDateString();
  }

  selectDate(day: number, inputMonth: number) {
    this.errorMessage = null;
    this.month = inputMonth;
    const selectedDate = moment().year(this.year).month(this.month).date(day);
    this.datePickerInputValue = this.formatMomentDateString(selectedDate);
    this.formGroup.get(this.controlName).setValue(selectedDate.toDate());
  }

  getNoOfDays() {
    const daysInMonth = moment().year(this.year).month(this.month).daysInMonth();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  }

  getPrevMonthDays() {
    const daysInPrevMonth = moment([this.year, this.month]).subtract(1, 'month').daysInMonth();
    const dayOfWeek = moment([this.year, this.month, 1]).day();
    return Array.from({length: dayOfWeek}, (_, i) => daysInPrevMonth - dayOfWeek + i + 1);
  }

  getNextMonthDays() {
    const daysToShow = ((MAX_DAYS_IN_ROW * MAX_COLUMNS) - [...this.getPrevMonthDays(), ...this.getNoOfDays()].length);
    return Array.from({ length: daysToShow }, (_, i) => i + 1);
  }

  setDatePickerState(state: DatePickerState, event?: MouseEvent) {
    event?.preventDefault();
    setTimeout(() => {
      this.datePickerState = state;
      switch (this.datePickerState) {
        case DatePickerState.SELECT_DAY: {
          this.shouldDatePickerStateBeVisible[DatePickerState.SELECT_DAY] = true;
          this.shouldDatePickerStateBeVisible[DatePickerState.SELECT_MONTH] = false;
          this.shouldDatePickerStateBeVisible[DatePickerState.SELECT_YEAR] = false;
          break;
        }
        case DatePickerState.SELECT_MONTH: {
          this.shouldDatePickerStateBeVisible[DatePickerState.SELECT_DAY] = false;
          this.shouldDatePickerStateBeVisible[DatePickerState.SELECT_MONTH] = true;
          this.shouldDatePickerStateBeVisible[DatePickerState.SELECT_YEAR] = false;
          break;
        }
        case DatePickerState.SELECT_YEAR: {
          this.shouldDatePickerStateBeVisible[DatePickerState.SELECT_DAY] = false;
          this.shouldDatePickerStateBeVisible[DatePickerState.SELECT_MONTH] = false;
          this.shouldDatePickerStateBeVisible[DatePickerState.SELECT_YEAR] = true;
          break;
        }
        default: break;
      }
      setTimeout(() => this.shouldDatePickerStateBeVisible[state] = true, DATE_PICKER_ANIMATION_DURATION);
    });
  }

  changeMonth(direction: number) {
    this.month += direction;
    if (this.month < 0) {
      this.month = 11;
      this.year--;
    } else if (this.month > 11) {
      this.month = 0;
      this.year++;
    }
  }

  setDateFromInput(event: KeyboardEvent | FocusEvent) {
    event.preventDefault();

    const expectedFormat = this.dateFormat;
    const parsedDate = moment(this.datePickerInputValue, expectedFormat, true);

    // Check if the parsed date is valid and matches the expected format
    if (parsedDate.isValid() && parsedDate.format(expectedFormat) === this.datePickerInputValue) {
      this.year = parsedDate.year();
      this.lastYearSelected = this.year;
      this.month = parsedDate.month();

      // Get the number of days in the selected month
      this.getNoOfDays();

      // Select the date using the day of the month and month
      this.selectDate(parsedDate.date(), this.month);

      this.errorMessage = null;
      this.datePickerState = DatePickerState.SELECT_DAY;
    }
  }

  selectMonth(monthId: number) {
    this.month = monthId;
    this.setDatePickerState(DatePickerState.SELECT_DAY);
  }

  selectYear(yearInput: number) {
    this.lastYearSelected = this.year;
    this.year = yearInput;
    this.setDatePickerState(DatePickerState.SELECT_MONTH);
  }

  isCurrentYear(yearInput: number) {
    const today = new Date();
    return today.getFullYear() === yearInput;
  }

  isYearSelected(yearInput: number) {
    return this.year === yearInput;
  }

  navigationLeft() {
    switch (this.datePickerState) {
      case DatePickerState.SELECT_DAY: this.changeMonth(-1); break;
      case DatePickerState.SELECT_YEAR: {
        this.setRenderYearsFrom(this.yearsToRender[0], -1);
        break;
      }
      default: break;
    }
  }

  navigationRight() {
    switch (this.datePickerState) {
      case DatePickerState.SELECT_DAY: this.changeMonth(1); break;
      case DatePickerState.SELECT_MONTH: {
        this.setDatePickerState(DatePickerState.SELECT_DAY);
        this.year = this.lastYearSelected;
        break;
      }
      case DatePickerState.SELECT_YEAR: {
        this.setRenderYearsFrom(this.yearsToRender[this.yearsToRender.length - 1], 1);
        break;
      }
      default: break;
    }
  }

  setRenderYearsFrom(yearInput: number, direction: number, length: number = YEAR_SELECT_LENGTH) {
    this.yearsToRender = Array.from({ length: length }, (_, i) => yearInput + (i * direction) + direction);
    if (direction < 0) {
      this.yearsToRender.reverse();
    }
  }

  hasInputErrors() {
    return !this.formGroup.get(this.controlName).valid && this.formGroup.get(this.controlName).dirty;
  }
}
