import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {Profile} from '@shared/models/profile';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {Observable} from 'rxjs';
import {debounceTime, filter, finalize, first} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {defaultDebounceTime, getLogoPath} from '@app/shared-module/utils/utils';
import {TranslateService} from '@ngx-translate/core';
import {LangInfo, LanguageService} from '@app/core-module/services/language.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  animations: [routerTransition()]
})
export class ForgotPasswordComponent implements OnInit {

  private currentProfile: Observable<Profile>;
  forgotPasswordSent = false;
  errorMessage = '';
  errorDisplay = false;

  currentLang$: Observable<LangInfo>;


  formGroup: FormGroup;

  working$ = new BehaviorSubject<boolean>(false);
  working = this.working$.asObservable().pipe(debounceTime(defaultDebounceTime));
  sentOnce = false;
  logoPath: string;

  constructor(private currentProfileState: CurrentProfileState,
              private authenticationService: AuthenticationService,
              private alertService: AlertsService,
              private router: Router,
              private translateService: TranslateService,
              private languageService: LanguageService) {
    this.logoPath = getLogoPath();
    this.currentProfile = currentProfileState.getCurrentProfile();
    this.currentLang$ = languageService.getSelectedLanguage$();
  }

  ngOnInit() {
    this.currentProfile.pipe(
      filter(it => it !== null),
      first()
    ).subscribe(profile => {
      if (profile.id) {
        if (profile.user != null && profile.user.isAdminFI) {
          this.router.navigate(['/admin']);
        } else if (profile.user != null) {
          this.router.navigate(['/fi']);
        } else {
          this.router.navigate(['/profile']);
        }
      }
    });
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  resetPassword() {
    this.working$.next(true);
    this.authenticationService.resetPassword(this.formGroup.get('email').value)
      .pipe(finalize(() => this.working$.next(false)))
      .subscribe((res) => {
          this.forgotPasswordSent = true;
          this.sentOnce = true;
          if (this.sentOnce) {
            this.alertService.addAlert({type: 'success', message: this.translateService.instant('root.forgotPassword: Reset link has been sent')});
          }
        },
        (err) => {
          this.alertService.addAlert({type: 'danger', message: this.translateService.instant('root.forgotPassword: Error while resetting password!')});
        });
  }

  setLanguage(languageCode: string) {
    this.languageService.setLanguage(languageCode);
  }


}
