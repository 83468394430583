<div class="fixed top-0 right-0 bottom-0 z-20 flex justify-center m-10 text-base">
  <div *ngFor="let alert of alerts" (close)="removeAlert(alert)">
    <div [ngClass]="{'bg-green-50 border-green-500 text-success': alert.type==='success',
                      'bg-red-50 border-red-500 text-danger': alert.type==='danger'}"
         class="flex flex-col py-5 px-10 gap-5 border-2 rounded-2xl shadow-lg animate-slideIn">
      <div class="flex justify-end cursor-pointer w-full" (click)="removeAlert(alert)">
        <div class="text-dark -mr-5">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0.5"
               stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"/>
          </svg>
        </div>
      </div>
      <div class="flex justify-center items-end mb-2 gap-2">
          <span class="mb-0.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke-width="1.5" stroke="currentColor" class="size-6">
              <path *ngIf="alert.type==='danger'" stroke-linecap="round" stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"/>
              <path *ngIf="alert.type==='success'" stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
            </svg>
          </span>
        {{ alert.message }}
      </div>
    </div>
  </div>
</div>

<div class="debug-box">
  <ngb-alert *ngFor="let alert of debugAlerts" (close)="removeDebugAlert(alert)"
             type="{{alert.type}}">{{ alert.message }}
  </ngb-alert>
</div>
<router-outlet></router-outlet>




