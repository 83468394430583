import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {of} from 'rxjs/internal/observable/of';
import {getAccessToken, getRefreshToken} from '@app/shared-module/utils/utils';
import {Socket} from 'ngx-socket-io';

interface LoginResponse {
  message: string;
  accessToken: string;
  refreshToken: string;
}

interface SignUpResponse {
  message: string;
}

@Injectable()
export class AuthenticationService {

  constructor(private http: HttpClient,
              private currentProfileState: CurrentProfileState,
              private socket: Socket) {

  }

  refreshAccessToken() {
    return of(null).pipe(
      map(() => getRefreshToken()),
      switchMap(currentRefreshToken =>
        this.http.post<LoginResponse>(environment.domain + '/auth/token', {
          refreshToken: currentRefreshToken,
        })
      ),
      tap((body) => {
        localStorage.setItem('session', JSON.stringify({
          accessToken: body.accessToken,
          refreshToken: body.refreshToken
        }));
        this.socket.emit('authenticate', {token: getAccessToken()});
      })
    );
  }

  login(email: string, password: string, tfaCode: string, recaptcha: string): Observable<HttpResponse<LoginResponse>> {
    return this.http.post<HttpResponse<LoginResponse>>(
      environment.domain + '/auth/login',
      {email: email, password: password, recaptcha: recaptcha, tfaCode: tfaCode},
      {observe: 'response'}).pipe(
      tap((res: any) => {
        if (res.status === 200) {
          // store email and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('session', JSON.stringify({
            accessToken: res.body.accessToken,
            refreshToken: res.body.refreshToken
          }));
          this.socket.emit('authenticate', {token: getAccessToken()});
        }
        this.currentProfileState.reload();
      })
    );
  }

  public signUp(form): Observable<SignUpResponse> {
    return this.http.post<SignUpResponse>(environment.domain + '/auth/sign-up', form);
  }

  public activate(code): Observable<any> {
    return this.http.get(environment.domain + '/auth/activate/' + code);
  }

  public resetPassword(email: string): Observable<any> {
    return this.http.get(environment.domain + '/auth/forgot-password/' + email);
  }

  public changePassword(form) {
    return this.http.post(environment.domain + '/auth/change-password', form);
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    localStorage.removeItem('session');
    if (!window.location.pathname.startsWith('/auth/login')) { // check to avoid infinite page loads
      window.location.href = '/auth/login';
    }
  }
}
