import {Injectable} from '@angular/core';
import {ApiService} from '@app/core-module/services/api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {FinancialInstitution} from '@shared/models/financial-institution';
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, merge, tap} from 'rxjs/operators';
import {Profile} from '@shared/models/profile';
import {FIAPIConfig} from '@shared/utils/api';

@Injectable()
export class CurrentBankState {

  private currentBank: Observable<FinancialInstitution>;
  private reading: Observable<boolean>;

  private currentBankWithAPI: Observable<FinancialInstitution>;
  private readingWithAPI: Observable<boolean>;

  private readBank$ = new BehaviorSubject(true);


  constructor(private apiService: ApiService,
              private currentProfileState: CurrentProfileState,
              private rxUtilsService: RxUtilsService) {

    const initiator = this.currentProfileState.getCurrentProfile().pipe(
      filter((profile: Profile) => profile != null && profile.user != null),
      merge(this.readBank$)
    );
    this.currentBank = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getCurrentBank(),
      'Error while reading current bank!', null);
    this.reading = this.rxUtilsService.createReadingInfo(initiator, this.currentBank);

    // API
    this.currentBankWithAPI = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getCurrentBankWithAPI(),
      'Error while reading current bank!', null);
    this.readingWithAPI = this.rxUtilsService.createReadingInfo(initiator, this.currentBankWithAPI);
  }

  getCurrentBank(): Observable<FinancialInstitution | null> {
    return this.currentBank;
  }

  getCurrentBankId(): Observable<number | null> {
    return this.getCurrentBank().pipe(
      map(bank => bank ? bank.id : null),
      distinctUntilChanged()
    );
  }

  /**
   * Returns Observable emitting only true of false, never null or an error.
   */
  getReadingCurrentBank(): Observable<boolean> {
    return this.reading.pipe(distinctUntilChanged());
  }

  update(bank: FinancialInstitution) {
    return this.apiService.updateCurrentBank(bank).pipe(
      tap(() => this.readBank$.next(true))
    );
  }

  getCurrentBankWithAPI(): Observable<FinancialInstitution | null> {
    return this.currentBankWithAPI;
  }

  getReadingCurrentBankWithAPI(): Observable<boolean> {
    return this.readingWithAPI.pipe(distinctUntilChanged());
  }

  updateAPIConfig(apiConfig: FIAPIConfig) {
    return this.apiService.updateCurrentBankAPIConfig(apiConfig).pipe(
      tap(() => this.readBank$.next(true))
    );
  }
}
