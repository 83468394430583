import {Component, Input, OnInit} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {Observable} from 'rxjs/internal/Observable';
import {RoutingUtilsService} from '@app/core-module/services/routing-utils.service';
import {map} from 'rxjs/operators';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: "design-navigation-item",
  templateUrl: "./navigation-item.component.html",
  styleUrls: ["./navigation-item.component.scss"],
  animations: [routerTransition()]
})
export class NavigationItemComponent implements OnInit {

  @Input() title: string;
  @Input() routerLink: string;

  isActive$: Observable<boolean>;

  constructor(private routingUtilsService: RoutingUtilsService) {

  }

  ngOnInit() {
    this.isActive$ = this.routingUtilsService.getCurrentUrl().pipe(
      map(it => it === this.routerLink)
    );
  }
}


